import { Outlet} from "react-router-dom";
import AdminDashboardSidebar from "../../../components/Admin/Dashboard/AdminDashboardSidebar";
import { Helmet } from "react-helmet-async";

export default function AdminHome(){

    return (
        <main className="w-screen h-screen flex overflow-hidden">
            <Helmet>
                <title>Gen AI Interviewer </title>
                <meta name="description" content="Athmick AI revolutionizes recruitment with AI-driven, automated solutions that enable hiring best-fit candidates within 24 hours." />
            </Helmet>
            <AdminDashboardSidebar />
            <div style={{ width: `calc(100vw - 240px)` }} className="bg-[#ECF5FA] flex-col h-full overflow-hidden max-sm:!w-full w-full justify-center items-center flex">
                <div className="overflow-y-scroll h-full w-full flex justify-center">
                    <div className="h-full w-full">
                        <Outlet />
                    </div>
                </div>
            </div>
        </main>
    )
}

// JD_Machine Learning Engineer.pdf