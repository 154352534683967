import React, { createContext, useContext, useState, useEffect } from 'react';
import LoadingComponent from '../components/Common/LoadingComponent';
import ConsoleHelper from '../utils/consoleHelper';

const ImageContext = createContext();

const loadImages = async () => {
    ConsoleHelper.log("Loading viseme images...");
    let imageMap = {};

    for (let i = 0; i <= 21; i++) {
        const img = new Image();
        img.src = `https://skillassessment.blob.core.windows.net/images/bot-images/viseme-${i}.png`;

        try {
            await img.decode(); // Ensure the image is fully loaded before storing
            imageMap[i] = img.src;
            ConsoleHelper.log(`Loaded viseme-${i}.png`);
        } catch (error) {
            ConsoleHelper.error(`Failed to load viseme-${i}.png`, error);
        }
    }

    return imageMap;
};

export const ImageProvider = ({ children }) => {
    const [images, setImages] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async () => {
            const preloadedImages = await loadImages();
            setImages(preloadedImages);
            setLoading(false);
        };

        fetchImages();
    }, []);

    return (
        <ImageContext.Provider value={{ images, loading }}>
            {loading ? (
                <div className='h-screen w-screen flex justify-center items-center'>
                    <LoadingComponent />
                </div>
            ) : children}
        </ImageContext.Provider>
    );
};

// Custom hook to use the images in other components
export const useImages = () => useContext(ImageContext);
