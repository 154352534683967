import { useRef, useState } from 'react';
import {AiOutlineUpload} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { MdKeyboardArrowLeft } from "react-icons/md";
import BlogService from '../../../services/blog.service';
import { useToast } from '../../../contextProviders/ToastContext';
import Editor from '../../../components/Admin/BlogManagement/Editor';
import LoadingComponent from '../../../components/Common/LoadingComponent';
import CommonService from '../../../services/common.service';
import { extractBlogFilePath } from '../../../utils/common';
import axios from 'axios';
import ConsoleHelper from '../../../utils/consoleHelper';

export default function BlogManagementCreate(){
    const fileInputRef = useRef(null);
    const [error, setError] = useState({});
    const [title, setTitle] = useState(null);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(null);
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState(null);
    const {showSuccessMessage, showErrorMessage} = useToast();
    const [imagePreview, setImagePreview] = useState(null);

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const navigateTo = (path) => {
        navigate(path)
    }

    const handleChange = (html) => {
        setContent(html)
    }

    const validate = () => {
        setError({})
        const error = {};
        if(!content || content?.trim()?.length === 0)
            error['content'] = 'Content required';
        if(!description || description?.trim()?.length === 0)
            error['description'] = 'Description required';
        if(!image)
            error['image'] = 'Image required';
        if(!title || title?.trim()?.length === 0)
            error['title'] = 'Title required';


        if(Object.keys(error).length){
            setError(error)
            return false;
        }
        return true;
    }

    const handleInput = (key, event) => {
        const value = event?.target?.value;
        if(key === "title")
            setTitle(value)
        else if(key === "description")
            setDescription(value)
    }

    const handleFileChange = async event => {
        try {
            const file = event.target.files[0];
            if(!file) throw new Error("Please select file");
            setImage(file);
            let reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);

        } catch (error) {
            ConsoleHelper.error(`error: ${JSON.stringify(error)}`);
        }
    };


    const submit = async (event) => {
        event.preventDefault(); 
        if(!validate()) return;

        try {
            setLoading(true);
            const fileName = new Date().getTime() + '-' + image?.name;
            const presignUrl = await CommonService.generatePresignUrlForBlogs(fileName);
            const {url} = presignUrl.data.data;
            await axios.put(url, image, {
                headers: { 'x-ms-blob-type': 'BlockBlob'}
            });
            const requestData = {
                content,
                title,
                description,
                imageUrl: extractBlogFilePath(url, '-'),
            };
            const response = await BlogService.createBlog(requestData);
            if(response.status === 201){
                setLoading(false);
                showSuccessMessage({
                    summary: 'Created Blog', 
                    detail: 'Successfully created blog'
                })
                navigateTo('/dashboard/blogs-management')
            }
        } catch (error) {
            setLoading(false);
            const message = error?.response?.data?.message;
            showErrorMessage({
                summary: 'Failed', 
                detail: message || 'Failed to create blog'
            })
        }
    }
  
    return (
        <div className="flex flex-col min-h-full gap-4 p-5">
            <div className={`${loading ? 'visible': 'hidden'} h-screen w-screen top-0 left-0 absolute z-50 bg-transparent justify-center flex items-center`}>
                <div className='w-40'>
                    <LoadingComponent />
                </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div 
                    onClick={goBack}
                    className="flex items-center gap-1 text-primary cursor-pointer">
                    <MdKeyboardArrowLeft size={26} />
                    <h1 className='text-xl text-primary font-bold'>Create Blog</h1>
                </div>
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col gap-3'>
                <div className="flex-col flex gap-2">
                    <label className="text-darkGray font-medium text-sm" htmlFor="title">Title</label>
                    <InputText 
                        id="title"
                        value={title}
                        placeholder="Enter Title" 
                        onChange={handleInput.bind(this, 'title')}
                        className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                    />
                    {(error['title'] || (!title || title?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['title']}</span>}
                </div>
                <div className="flex-col flex gap-2">
                    <label className="text-darkGray font-medium text-sm" htmlFor="description">Description</label>
                    <InputText 
                        id="description"
                        value={description}
                        placeholder="Enter description"
                        onChange={handleInput.bind(this, 'description')} 
                        className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                    />
                    {(error['description'] || (!description || description?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['description']}</span>}
                </div>
                <div className="flex-row flex justify-between items-center">
                    <div className='flex flex-col gap-2 w-2/3'>
                        <label className="text-darkGray font-medium text-sm" htmlFor="image">Upload Image</label>
                        <span className={`${image ? 'visible': 'hidden'} overflow-hidden line-clamp-1 text-xs text-blue`}>{image?.name}</span>
                        <div
                            onClick={() => fileInputRef.current.click()} 
                            className="border-blue border cursor-pointer w-36 text-blue px-2 h-9 rounded font-semibold text-xs flex items-center gap-4 hover:bg-blue hover:text-white">
                            <span>Upload Banner</span>
                            <AiOutlineUpload size={16}/>
                        </div>
                        <input 
                            type="file" 
                            accept="image/*"
                            ref={fileInputRef} 
                            className="hidden"
                            onChange={handleFileChange} 
                        />
                        {(error['image'] || !image) && <span className="text-dr font-normal text-xs">{error['image']}</span>}
                    </div>
                    <div className='w-1/3 flex justify-end'>
                        <img
                            alt='Blog'
                            className='h-20 w-40'
                            src={imagePreview}
                        />
                    </div>
                </div>
                <div className='flex-col flex gap-2'>
                    <label className="text-darkGray font-medium text-sm" htmlFor="content">Content</label>
                    <Editor
                        editorHtml={content} 
                        handleChange={handleChange}
                        placeholder="Start writing blog"
                    />
                    {(error['content'] || content?.trim()?.length === 0) && <span className="text-dr font-normal text-xs">{error['content']}</span>}
                </div>
            </div>
            <button 
                onClick={submit}
                className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-40 mt-5'>
                Create
            </button>
        </div>
    )
}