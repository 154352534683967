import { useEffect, useState } from "react";
import {BounceLoader} from 'react-spinners';
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { InputText } from "primereact/inputtext";
import logo from './../../assets/icons/logo-2.svg';
import { useAuth } from "../../contextProviders/AuthContext";
import { useToast } from "../../contextProviders/ToastContext";
import pattern from './../../assets/images/pattern-3.svg';
import { Helmet } from "react-helmet-async";

export default function LoginPage(){
    const {login} = useAuth();
    const navigate = useNavigate();
    const [password, setPassword] = useState(null);
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const {showErrorMessage} = useToast();

    const requestLogin = async () => {
        try {
            setLoading(true);
            const response = await AuthService.login({email, password});
            setLoading(false)
            if(response.status === 200){
                const {data} = response.data;
                const {user, tokens} = data;
                const {access, refresh} = tokens;
                login(user, access, refresh)
                navigate('/dashboard')
            }
        } catch (error) {
            setLoading(false)
            showErrorMessage({ 
                summary: 'Failed', 
                detail: error?.response?.data?.message  || 'Invalid email or password'
            })
        }
    }

    const submit = async (event) => {
        event.preventDefault();
        await requestLogin();
    }

    return (
        <div className="w-screen h-screen justify-center items-center flex" style={{backgroundImage: `url(${pattern})`}}>
            <Helmet>
                <title>Login | Gen AI Interviewer </title>
                <meta name="description" content="This is the login page of athmick." />
            </Helmet>
            <div className="bg-white shadow-[1px_4px_10px_rgba(0,0,0,0.12)] items-start w-[80%] md:w-[60%] xl:w-[25%] justify-center flex flex-col p-6 rounded-xl">
                <img 
                    src={logo}
                    alt="logo"
                    className="h-12 2xl:h-14"
                />
                <h1 className="text-primary font-semibold text-xl mt-5">Welcome to Athmick 🖐</h1>
                <form onSubmit={submit} className="w-full mt-10 gap-5 flex flex-col text-sm">
                    <div className="flex flex-col gap-1">
                        <label className="text-primary font-medium text-xs">Email</label>
                        <InputText 
                            value={email}
                            className="border border-l_border h-10 px-2 rounded-md text-sm"
                            placeholder="Enter email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label className="text-primary font-medium text-xs">Password</label>
                        <input
                            value={password}
                            type="password"
                            placeholder="Enter Password"
                            className="border border-l_border h-10 px-2 rounded-md text-sm"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue h-10 rounded text-white mt-10 justify-center flex items-center">
                        {loading && (
                            <BounceLoader 
                                size={26} 
                                color="#ffffff"
                            />
                        )}
                        {!loading && (
                            <span className="font-semibold text-xs 2xl:text-sm">Login</span>
                        )}
                    </button>
                </form>
            </div>
        </div>
    )
}