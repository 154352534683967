import PropTypes from 'prop-types';
import { useRef, useState } from "react";
import { Chip } from "primereact/chip";
import { Editor } from "primereact/editor";
import {AiOutlineUpload} from 'react-icons/ai';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import JDService from "../../../services/jd.service";
import { getEditorHeaders } from "../../../utils/common";
import TextExtractor from "../../../utils/text-extractor";
import CommonService from "../../../services/common.service";
import { useToast } from "../../../contextProviders/ToastContext";
import { useJDInfo } from "../../../contextProviders/JDInfoContext";
import LoadingComponent from '../../../components/Common/LoadingComponent';
import ConsoleHelper from '../../../utils/consoleHelper';

const employmentTypes = [
    {name: 'Full-time', code: 'Full-time'},
    {name: 'Part-time', code: 'Part-time'},
    {name: 'Contract', code: 'Contract'},
    {name: 'Internship', code: 'Internship'},
]

const experiences = [
    { name: "0-1 years", code: "0-1 years"},
    { name: "1-3 years", code: "1-3 years"},
    { name: "3-5 years", code: "3-5 years"},
    { name: "5-10 years", code: "5-10 years"},
    { name: "10-15 years", code: "10-15 years"},
    { name: "15+ years", code: "15+ years"}
]

function JDGeneratePage(props){
    const {setActiveTabIndex} = props;

    const { showErrorMessage } = useToast();
    const {getJDInfo, changeJDInfo, clearJD} = useJDInfo();

    const jdInfo = getJDInfo();
    const fileInputRef = useRef(null);
    const [error, setError] = useState({});
    const editorHeader = getEditorHeaders();

    const [uploading, setUploading] = useState(false);
    const [isGeneratedJd, setIsGeneratedJd] = useState(null);
    
    const [title, setTitle] = useState(jdInfo?.title || null);
    const [jdHtml, setJDHtml] = useState(jdInfo?.jdHtml || null);
    const [location, setLocation] = useState(jdInfo?.location || null);

    const initialExperience = 
    typeof jdInfo?.['experience'] === "string" ? { name: jdInfo?.['experience'], code: jdInfo?.['experience'] } :  
    typeof jdInfo?.['experience'] === "object" ? jdInfo?.['experience']  : {};

    const [experience, setExperience] = useState(initialExperience);

    const initialEmploymentType = 
        typeof jdInfo?.['employmentType'] === "string" ? { name: jdInfo?.['employmentType'], code: jdInfo?.['employmentType'] } :  
        typeof jdInfo?.['employmentType'] === "object" ? jdInfo?.['employmentType']  : {};

    const [employmentType, setEmploymentType] = useState(initialEmploymentType);
    const [jdSelectedOption, setJDSelectedOption] = useState(jdInfo?.jdSelectedOption || null);
    const [primarySkillText, setPrimarySkillsText] = useState(jdInfo?.primarySkills ? jdInfo?.primarySkills?.join(",") : null);
    const [secondarySkillsText, setSecondarySkillsText] = useState(jdInfo?.secondarySkills ? jdInfo?.secondarySkills?.join(",") : null);

    const handleChanges = (key, value) => changeJDInfo({[key]: value})

    const handleFileChange = async event => {
        try {
            const file = event.target.files[0];
            if(!file){
                showErrorMessage({
                    summary: 'Pdf file required', 
                    detail: 'Please select file'
                })
                return;
            }

            const isPdfFile = file?.name?.toLowerCase().endsWith('.pdf');
            
            if(!isPdfFile){
                showErrorMessage({
                    summary: 'Required pdf file', 
                    detail: 'We only allow pdf files'
                })
                return;
            }

            clearJD();
            setUploading(true);
            setIsGeneratedJd(false);
            setJDSelectedOption("UPLOAD");
            handleChanges('selectedOption', 'UPLOAD')
            setJDHtml(null);
            if(!file) throw new Error("Please select file");
            const result = await TextExtractor.extractText(file.type, file);
            const promises = [convertTextToHtml(result, file)];
            // if(shouldExtractJDInfo())
            promises.push(extractJDInfo(result))
            await Promise.all(promises)
            setUploading(false);

        } catch (error) {
            setUploading(false);
            ConsoleHelper.error(`error: ${JSON.stringify(error)}`);
        }
    };

    const validate = () => {
        setError({})
        const error = {};
        if(!title)
            error['title'] = 'Title required';
        if(!experience?.code)
            error['experience'] = 'Experience required';
        if(!primarySkillText || primarySkillText?.trim().length === 0)
            error['primarySkills'] = 'Primary skills required';
        if(!secondarySkillsText || secondarySkillsText?.trim().length === 0)
            error['secondarySkills'] = 'Secondary skills required';
        if(Object.keys(error).length){
            setError(error)
            return false;
        }
        return true;
    }

    const generateJDSummary = async () => {
        try {
            if(!validate()) return

            setUploading(true);
            setIsGeneratedJd(true);
            setJDSelectedOption("GENERATE");
            setJDHtml(null);
            const primarySkills = primarySkillText.split(',').filter(word => word.trim());
            const secondarySkills = secondarySkillsText.split(',').filter(word => word.trim());
            const response = await JDService.generateJD({
                jobTitle: title,
                experience: experience?.code,
                primarySkills: primarySkillText?.includes(",") ? primarySkills : [primarySkillText],
                secondarySkills: secondarySkillsText?.includes(",") ? secondarySkills : [secondarySkillsText],
            });
            setUploading(false);
            if(response.status === 200){
                const jdHtml = response.data.data?.replace('<!DOCTYPE html>', '');
                setJDHtml(jdHtml)
                changeJDInfo({ jdHtml: jdHtml, jdSelectedOption: 'GENERATE'})
            }
        }
        catch (error) {
            setUploading(false);
            showErrorMessage({
                summary: 'Failed', 
                detail: error?.response?.data?.message
            })
        }
    }

    const convertTextToHtml = async (jd, file) => {
        try {
            setIsGeneratedJd(false);
            const requestData = {text: jd}
            const response = await CommonService.getHtmlFromText(requestData);
            if(response.status === 200){
                const {data} = response.data;
                const parsedHtml = data?.replaceAll('```html\n', '').replaceAll('```', '');
                setJDHtml(parsedHtml);
                changeJDInfo({
                    jdHtml: parsedHtml,
                    uploadedJDFile: file,
                    jdSelectedOption: 'UPLOAD'
                })
            } 
            else throw Error("failed to convert text to html")
        }
        catch (error) {
            const message = error?.response?.data?.message
            showErrorMessage({
                summary: 'Failed', 
                detail: message
            })
        }
    }


    const extractJDInfo = async (jd) => {
        try {
            setIsGeneratedJd(false);
            const extractedJDInfo = await CommonService.extractJDInfo({jd});
            if(extractedJDInfo.status === 200){
                const jdInfo = extractedJDInfo.data.data;
                const data = {};
                if(jdInfo.title && (!title || title?.trim()?.length === 0)){
                    setTitle(jdInfo.title)
                    data['title'] = jdInfo.title;
                }
                if(jdInfo.experience){
                    setExperience({name: jdInfo.experience, code: jdInfo.experience})
                    data['experience'] = {name: jdInfo.experience, code: jdInfo.experience};
                }
                if(jdInfo.location){
                    setLocation( (jdInfo.location && jdInfo.location?.length) > 0 ? jdInfo?.location?.join(",") : "")
                    data['location'] = (jdInfo.location && jdInfo.location?.length) > 0 ? jdInfo?.location?.join(",") : ""
                }
                if(jdInfo.employmentType){
                    setEmploymentType({name: jdInfo.employmentType, code: jdInfo.employmentType})
                    data['employmentType'] = {name: jdInfo.employmentType, code: jdInfo.employmentType};
                }
                if(jdInfo.primarySkills && (!primarySkillText || primarySkillText?.trim()?.length === 0)){
                    setPrimarySkillsText(jdInfo?.primarySkills?.join(","))
                    data['primarySkills'] = jdInfo.primarySkills;
                }
                if(jdInfo.secondarySkills && (!secondarySkillsText || secondarySkillsText?.trim()?.length === 0)){
                    setSecondarySkillsText(jdInfo?.secondarySkills?.join(","))
                    data['secondarySkills'] = jdInfo.secondarySkills;
                }
                changeJDInfo(data)
            } 
        }
        catch (error) {
            setUploading(false);
            ConsoleHelper.error(JSON.stringify(error));
            const message = error?.response?.data?.message;
            showErrorMessage({ summary: 'Failed', detail: message })
        }
    }

    const onNext = () => {
        if(!jdSelectedOption || (jdSelectedOption === "GENERATE" && !jdHtml)){
            showErrorMessage({summary: 'Required', detail: 'Either Generate or Upload JD'})
            return;
        }

        changeJDInfo({jdHtml: jdHtml})

        if(!validate()) return;
        setActiveTabIndex(1);
    }

    return (
        <div className="flex flex-col h-full gap-5">
            <div className={`${uploading? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-10 rounded-md w-1/4 flex justify-center items-center">
                    <LoadingComponent />
                </div>
            </div>
            <div className='flex gap-6 text-brownGray items-center justify-end'>
                <div
                    onClick={() => fileInputRef.current.click()} 
                    className="border-blue border cursor-pointer text-blue px-2 h-9 rounded font-semibold text-xs flex items-center gap-4 hover:bg-blue hover:text-white">
                    <span>Upload JD</span>
                    <AiOutlineUpload size={16}/>
                </div>
            </div>
            <input 
                type="file" 
                accept=".pdf"
                ref={fileInputRef} 
                className="hidden"
                onChange={handleFileChange} 
            />
            <div className="bg-white p-5 rounded-md">
                <div className="bg-white rounded gap-10 flex flex-col mt-2">
                    <div className="flex gap-5">
                        <div className="flex-col flex flex-1 gap-2">
                            <label className="text-darkGray font-semibold text-sm" htmlFor="title">Role / Job Title</label>
                            <InputText 
                                id="title"
                                value={title}
                                placeholder="Enter title" 
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                    handleChanges('title', e.target.value) 
                                }}
                                className="border-l_border border-[1px] h-10 p-2"
                            />
                            {error['title'] && <span className="text-dr font-normal text-xs">{error['title']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2">
                            <label className="text-darkGray font-semibold text-sm" htmlFor="experience">Experience</label>
                            <Dropdown 
                                checkmark={true}
                                value={experience} 
                                optionLabel="name"
                                options={experiences}  
                                defaultValue={experience}
                                highlightOnSelect={false}
                                placeholder="Select experience" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={(e) => {
                                    setExperience(e.value)
                                    handleChanges('experience', e.value) 
                                }}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {error['experience'] && <span className="text-dr font-normal text-xs">{error['experience']}</span>}
                        </div>
                    </div>
                    <div className="flex gap-5">
                        <div className="flex-col flex flex-1 gap-2">
                            <label className="text-darkGray font-semibold text-sm" htmlFor="location">Location</label>
                            <InputText 
                                id="location"
                                value={location}
                                placeholder="Add comma to add multiple locations" 
                                onChange={(e) => {
                                    setLocation(e.target.value);
                                    handleChanges('location', e.target.value) 
                                }}
                                className="border-l_border border-[1px] h-10 p-2"
                            />
                            {error['location'] && <span className="text-dr font-normal text-xs">{error['location']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2">
                            <label className="text-darkGray font-semibold text-sm" htmlFor="employmentType">Employment Type</label>
                            <Dropdown
                                checkmark={true} 
                                optionLabel="name"  
                                value={employmentType}
                                highlightOnSelect={false}
                                options={employmentTypes}  
                                defaultValue={employmentType} 
                                onChange={(e) => {
                                    setEmploymentType(e.value)
                                    handleChanges('employmentType', e.value) 
                                }} 
                                placeholder="Select employment type" 
                                className="h-10 border-l_border border-[1px] " 
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {error['employmentType'] && <span className="text-dr font-normal text-xs">{error['employmentType']}</span>}
                        </div>
                    </div>
                    <div className="flex gap-5 flex-col">
                        <div className="flex-col flex flex-1 gap-2">
                            <label className="text-darkGray font-semibold text-sm" htmlFor="primary-skills">Primary Skills</label>
                            <InputText 
                                id="primary-skills"
                                value={primarySkillText}
                                placeholder="Add comma to seperate skills" 
                                className="border-l_border border-[1px] h-10 p-2"
                                onChange={(event) => {
                                    setPrimarySkillsText(event.target.value)
                                    if(event.target.value && event.target.value.trim().length > 0)
                                        handleChanges('primarySkills', event.target.value.split(',').filter(word => word.trim()));
                                    else handleChanges('primarySkills', []) 
                                }}
                            />
                            {error['primarySkills'] && <span className="text-dr font-normal text-xs">{error['primarySkills']}</span>}
                            {primarySkillText && primarySkillText.split(',').filter(word => word.trim())?.length > 0 && (
                                <div className="flex flex-wrap gap-2 mt-2">
                                {primarySkillText.split(',').filter(word => word.trim()).map((item, index) => <Chip id={index} key={index} label={item}/>)}
                                </div>
                            )}
                        </div>
                        <div className="flex-1 flex flex-col gap-2">
                            <label className="text-darkGray font-semibold text-sm" htmlFor="Add comma to seperate skills">Secondary Skills</label>
                            <InputText 
                                id="secondary-skills"
                                value={secondarySkillsText}
                                placeholder="Enter Add comma to seperate skills"
                                className="border-l_border border-[1px] h-10 p-2"
                                onChange={(event) => {
                                    setSecondarySkillsText(event.target.value)
                                    if(event.target.value && event.target.value.trim().length > 0)
                                        handleChanges('secondarySkills', event.target.value.split(',').filter(word => word.trim())) 
                                    else handleChanges('secondarySkills', []) 
                                }}
                            />
                            {error['secondarySkills'] && <span className="text-dr font-normal text-xs">{error['secondarySkills']}</span>}
                            {secondarySkillsText && secondarySkillsText.split(',').filter(word => word.trim())?.length > 0 && (
                                <div className="flex flex-wrap gap-2 mt-2">
                                {secondarySkillsText.split(',').filter(word => word.trim()).map((item, index) => (
                                        <Chip id={index} key={index} label={item}/>
                                ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <button 
                        onClick={generateJDSummary}
                        className="border w-40 border-blue rounded h-10 text-blue font-medium">Generate</button>
                </div>
            </div>
            <div className={`bg-white p-5 rounded-md ${jdHtml ? 'visible': 'hidden'}`}>
                <div className="pb-10">
                    <label className="text-darkGray font-bold text-xl" htmlFor="additional-details">
                        Job Description {isGeneratedJd === null ? '' : isGeneratedJd ? '| Generated JD' : '| Uploaded JD'}
                    </label>
                    <Editor 
                        value={jdHtml} 
                        className="mt-5 min-h-40"
                        headerTemplate={editorHeader}
                        onTextChange={(e) => setJDHtml(e.htmlValue)}
                    />
                </div>
            </div>
            <div className={`rounded py-5 gap-10 flex justify-end ${jdHtml ? 'visible': 'hidden'}`}>
                <button 
                    onClick={onNext}
                    disabled={uploading}
                    className="bg-blue text-white h-10 rounded cursor-pointer font-semibold text-sm px-10">
                    Next
                </button>
            </div>
        </div>

    )
}

JDGeneratePage.propTypes = {
    setActiveTabIndex: PropTypes.func
}

export default JDGeneratePage;