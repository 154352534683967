import axios from "axios"
import moment from "moment/moment";

function uploadLog(token, sessionId, message){
    if(!sessionId || !token || !message) return;

    return axios.post(`${process.env.REACT_APP_API_URL}/screening/logs`, 
        {
            message: `${moment().format("DD-MMM-YYYY HH:mm:ss")} ${message}`,
            session: sessionId
        },
        { headers: { 'Authorization': `Bearer ${token}`}
    })
}

const LogService = { uploadLog }

export default LogService;

