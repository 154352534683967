import moment from "moment";
import { useEffect, useState } from "react"
import BlogService from "../../services/blog.service";
import { DataView } from 'primereact/dataview';
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../Common/LoadingComponent";

export default function BlogsPage(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [blogs, setBlogs] = useState([]);

    const navigateTo = (path) => {
        navigate(path);
    }

    useEffect(() => {
        async function init(){
            try {
                setLoading(true);
                const response = await BlogService.getBlogs();
                setLoading(false);
                if(response.status === 200)
                    setBlogs(response?.data?.data || [])
            } catch (error) {
                setLoading(false);
            }
        }
        init();
    }, []);

    const itemTemplate = (item, index) => {
        return (
            <div 
                key={item.id}
                className="w-full md:w-[40%] xl:w-[28%] shadow-lg hover:shadow-md hover:bg-white rounded-md col-12 sm:col-6 lg:col-12 xl:col-4">
                <div className="surface-border surface-card border-round flex justify-between flex-col">
                    <img 
                        alt="Blog"
                        src={item?.imageUrl}
                        className="cursor-pointer w-full h-60"
                        onClick={navigateTo.bind(this, `/blogs/${item?._id}`)}
                    />
                    <div className="flex flex-col p-4 gap-4 h-full">
                        <h1 className="text-sm">{item?.title}</h1>
                        <p className="text-base text-darkGray font-semibold line-clamp-2">{item?.description}</p>
                        <span className="text-xs font-semibold mt-auto">{moment(item?.createdAt).format('DD-MMM-YYYY')}</span>  
                    </div>
                </div>
            </div>
        );
    };

    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((item, index) => {
            return itemTemplate(item, index);
        });

        return <div className="flex gap-10 flex-wrap">{list}</div>;
    };

    if(loading){
        return (
            <div className='w-full md:max-w-[75%] mx-auto py-5 flex flex-col md:flex-row justify-center md:px-0 px-5 gap-5'>
                <div className="w-full py-10">
                    <LoadingComponent />
                </div>
            </div>
        )
    }

    if(!loading && blogs?.length === 0){
        return (
            <div className='w-full md:max-w-[75%] mx-auto py-5 flex flex-col md:flex-row justify-center md:px-0 px-5 gap-5'>
                <div className="w-full py-10 flex justify-center items-center">
                    <span>No blogs available</span>
                </div>
            </div>
        )
    }

    return (
        <div className='w-full md:max-w-[75%] mx-auto py-5 flex flex-col md:flex-row justify-center md:px-0 px-5 gap-5'>
            <div className="w-full py-10">
                <DataView 
                    value={blogs}  
                    // paginator
                    rows={10} 
                    layout="grid"
                    emptyMessage="No blogs available"
                    listTemplate={listTemplate}
                />
            </div>
        </div>
    )
}