import { Navigate, Route, Routes } from "react-router-dom";
import AdminHome from "../pages/Admin/Dashboard/AdminHome";
import AdminDashboard from "../pages/Admin/Dashboard/AdminDashboard";
import BlogManagement from "../pages/Admin/BlogManagement/BlogManagement";
import AdminUserCreate from "../pages/Admin/UserManagement/AdminUserCreate";
import BlogManagementHome from "../pages/Admin/BlogManagement/BlogManagementHome";
import AdminUserManagement from "../pages/Admin/UserManagement/AdminUserManagement";
import AdminUserManagementHome from "../pages/Admin/UserManagement/AdminUserManagementHome";
import BlogManagementCreate from "../pages/Admin/BlogManagement/BlogManagementCreate";
import BlogManagementUpdate from "../pages/Admin/BlogManagement/BlogManagementUpdate";

export default function AdminRoutes() {

    return (
        <Routes>
            <Route 
              path="" 
              element={<AdminHome />}>
                <Route 
                  index 
                  path="" 
                  element={<AdminDashboard />} 
                />
                <Route 
                  path="/users-management" 
                  element={<AdminUserManagementHome />} >

                  <Route 
                    index
                    path="" 
                    element={<AdminUserManagement />} 
                  />
                  <Route 
                    path="create" 
                    element={<AdminUserCreate />} 
                  />
                  {/* <Route 
                    path=":id/update" 
                    element={<AdminUserUpdate />}
                  /> */}
                </Route>

                <Route 
                  path="/blogs-management" 
                  element={<BlogManagementHome />} >

                  <Route 
                    index
                    path="" 
                    element={<BlogManagement />} 
                  />
                  <Route 
                    path="create" 
                    element={<BlogManagementCreate />} 
                  />
                  <Route 
                    path=":id/update" 
                    element={<BlogManagementUpdate />} 
                  />
                </Route>
                <Route 
                  path="*" 
                  element={<Navigate to="/notfound"/>}
                />
            </Route>
        </Routes>
    );
  }