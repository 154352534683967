import { useEffect, useState } from 'react';
import { CgProfile } from "react-icons/cg";
import logo from './../../../assets/icons/white-logo.svg';
import logoutLogo from './../../../assets/icons/logout.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import dashboardLogo from './../../../assets/icons/dashboard.svg'
import exitInterviewLogo from './../../../assets/icons/exit.svg'
import { useAuth } from '../../../contextProviders/AuthContext';
import userManagementLogo from './../../../assets/icons/user-management.svg'

export default function AdminDashboardSidebar(){
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedRoute, setSelectedRoute] = useState(1);

    const {user, logout} = useAuth();

    const logoutUser = () => {
        navigate('/auth/login')
        logout();
    }

    const routes = [
        {id: 1, Icon: dashboardLogo, route: '/dashboard', label: 'Dashboard', tooltipId: 'dashboard'},
        // {id: 2, Icon: jdLogo, route: '/dashboard/job-description', label: 'Job Description / Resumes', tooltipId: 'jd-create-upload'},
        {id: 2, Icon: userManagementLogo, route: '/dashboard/users-management', label: 'Users Management', tooltipId: 'user-management'},
        {id: 3, Icon: exitInterviewLogo, route: '/dashboard/blogs-management', label: 'Blogs Management', tooltipId: 'blogs'},
    ]

    useEffect(() => {
        if(location.pathname.includes('dashboard/users-management'))
            setSelectedRoute(2)
        else if(location.pathname.includes('dashboard/blogs-management'))
            setSelectedRoute(3)
        else
            setSelectedRoute(1)
    }, []);

    const selectRoute = (routeId, route) => {
        setSelectedRoute(routeId);
        navigate(route);
    }

    return (
        <div className="w-[240px] bg-primary h-screen flex flex-col items-center p-5">
            <div className='py-5'>
                <img 
                    src={logo} 
                    alt='logo'
                    className='h-12 xl:h-14'
                    onClick={() => navigate('/dashboard')}
                />
            </div>
            <hr className='border-0 border-b-[.1px] border-white w-full'/>
            <ul className='flex flex-1 flex-col w-full py-5 gap-4'>
                {routes.map(({id, Icon, route, label, tooltipId}) => (
                    <li 
                        key={id}
                        onClick={selectRoute.bind(this, id, route)}
                        className={`${id === selectedRoute ? 'bg-blue': 'bg-primary '} text-white cursor-pointer px-2 w-full h-10 gap-3 flex items-center relative ${label} rounded-sm`}>
                        {tooltipId !== 'configure' && (
                            <img 
                                src={Icon} alt='logo'
                                className={`h-4 w-4 xl:h-5 xl:w-5 ${tooltipId !== 'configure' ? 'visible': 'hidden'}`}
                            />
                        )}
                        {tooltipId === 'configure' && (
                            <Icon className={`h-4 w-4 xl:h-5 xl:w-5 ${tooltipId === 'configure'? 'visible': 'hidden'}`} />
                        )}
                        <h2 className='text-sm font-normal '>{label}</h2>
                    </li>
                ))}
            </ul>
            <hr className='border-0 border-b-[.1px] border-white w-full'/>
            <ul className='flex flex-2 flex-col w-full py-5 gap-5'>
                <li 
                    key="user"
                    className="cursor-pointer px-2 w-full h-10 gap-3 flex items-center relative rounded-sm">
                    <div className='h-7 w-7 bg-white rounded-full flex justify-center items-center'>
                        <img 
                            alt='logo'
                            src={user?.imageUrl}
                            className={`h-4 w-4 xl:h-5 xl:w-5 ${user?.imageUrl ? 'visible': 'hidden'}`}
                        />
                        <CgProfile
                            size={20} 
                            className={`${user?.imageUrl ? 'hidden': 'visible'} text-primary`}
                        />
                    </div>
                    <div className='text-white'>
                        <h3 className='text-sm font-medium'>{user?.firstName}</h3>
                        <span className='text-xs'>{user?.role?.toUpperCase()}</span>
                    </div>
                    {/* <img 
                        alt='logo'
                        src={arrowDropLogo}
                        className='h-4 w-4 xl:h-5 xl:w-5 ml-auto'
                    /> */}
                </li>
                {/* <li 
                    key="setting"
                    className="cursor-pointer w-full h-10 flex items-center justify-start relative rounded-sm">
                    <img 
                        alt='logo'
                        src={settingsLogo}
                        className='h-8 w-8 xl:h-10 xl:w-10'
                    />
                    <h2 className='text-sm text-white'>Settings</h2>
                </li> */}
                <li 
                    key="logout"
                    onClick={logoutUser}
                    className="cursor-pointer w-full h-10 flex items-center relative rounded-sm ">
                    <img 
                        alt='logo'
                        src={logoutLogo}
                        className='h-8 w-8 xl:h-10 xl:w-10'
                    />
                    <h2 className='text-sm text-white'>Logout</h2>
                </li>
            </ul>
        </div>
    )
}