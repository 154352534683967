import {MdClear} from 'react-icons/md';
import { useState } from "react";
import { Rating } from '@smastrom/react-rating';
import { useReportContext } from "../../contextProviders/ReportContext";
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';


export default function OverallReport(){
    const {report} = useReportContext();
    const [showAnomaly, setShowAnomaly] = useState(false);


    function getAnomalyAndSummary(){
        if(!report?.anomalies)
            return {anomaly: null, summary: null}

        const data = report?.anomalies?.replace('Interview Anomalies Data', '');
        const [anomaly, summary] = data.split('### Summary');
        return { anomaly, summary}
    }

    const getRating = (rating) => {
        if(rating < 20 || !rating) return { value: 1, title: 'REJECTED'}
        else if(rating < 39) return { value: 2, title: 'CONSIDER ALTERNATE CANDIDATE'}
        else if(rating < 59) return { value: 3, title: 'SUGGEST ON HOLD'}
        else if(rating < 74) return { value: 4, title: 'CONSIDER PROCEEDING'}
        else return { value: 5, title: 'READY TO PROCEED'}
    }

    return (
        <div className="p-5 bg-white rounded-md">
            {/* <div className='flex justify-center items-center flex-col pt-5 pb-16 gap-4'>
                <CircularProgress score={getRating(report?.total_score).value}/>
                <h2 className='text-sm text-primary font-semibold'>{getRating(report?.total_score).title}</h2>
            </div> */}
            <div className='flex flex-col gap-4 pt-10 py-20 items-center'>
                <Rating 
                    readOnly
                    color='#B99000' 
                    cancel={false} 
                    style={{width: 120}}
                    value={getRating(report?.total_score).value} 
                />
                {/* <div className={`${report?.total_score >= 50 && report?.total_score < 80 ? 'visible': 'hidden'} flex flex-col items-center gap-4`}> */}
                    <h2 className='text-sm text-primary font-semibold'>{getRating(report?.total_score).title}</h2>
                {/* </div> */}
            </div>
            <div className='flex flex-col gap-4'>
                <div className='flex items-center gap-2 text-primary'>
                    <h3 className='font-semibold w-40'>Job Title:</h3>
                    <span className='flex-1'>{report?.job_title}</span>
                </div>
                <div className='flex items-start gap-2 text-primary'>
                    <h3 className='font-semibold min-w-40'>Feedback:</h3>
                    <CustomReactMarkdown data={report?.feedback}/>
                </div>
                <div className={`${report?.anomalies_status ? 'visible': 'hidden'} flex gap-2 text-primary`}>
                    <h3 className='font-semibold min-w-40'>Anomalies detected:</h3>
                    <div className="flex flex-col gap-2">
                        <div className="flex gap-5 items-center">
                            <p className={`${report?.anomalies_status?.status === 'red'? 'text-dr': report?.anomalies_status?.status === 'green'? 'text-dg': 'text-do'} font-semibold`}>
                                {report?.anomalies_status?.description}
                            </p>
                            <button 
                                onClick={() => setShowAnomaly(true)} 
                                className={`text-sm cursor-pointer font-semibold text-blue ${getAnomalyAndSummary()?.anomaly?.toLowerCase()?.includes('not found') ? 'hidden': 'visible'}`}>
                                View more
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`${getAnomalyAndSummary()?.summary != null ? 'visible': 'hidden'} flex items-start gap-2 text-primary`}>
                    <h3 className='font-semibold  w-40'>Summary:</h3>
                    <CustomReactMarkdown data={getAnomalyAndSummary()?.summary}/>
                </div>
            </div>
            <div className={`h-screen w-screen absolute top-0 left-0 z-50 flex justify-center items-center bg-transparent ${showAnomaly ? 'visible': 'hidden'}`}>
                <div className="flex justify-center items-center relative w-[90%] xl:w-1/2">
                    <div className="h-9 w-9 rounded-full bg-white absolute -top-4 shadow-md z-20 -right-2 text-primary flex justify-center items-center cursor-pointer">
                        <MdClear size={22} onClick={() => setShowAnomaly(false)}/>
                    </div>
                    <div className={`bg-white rounded-md w-full overflow-hidden relative flex flex-col ${getAnomalyAndSummary()?.anomaly ? 'visible': 'hidden'}`}>
                        <div className="p-5 sticky top-0 shadow-md">
                            <h1 className={`text-xl font-bold ${report?.anomalies_status?.status === 'red'? 'text-dr': report?.anomalies_status?.status === 'green'? 'text-dg': 'text-do'}`}>Anomalies detected</h1>
                        </div>
                        <div className={`flex overflow-y-scroll h-full p-5`}>
                            <CustomReactMarkdown data={getAnomalyAndSummary()?.anomaly}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}