import axios from "axios";
import { useEffect, useState } from "react";
import JDGeneratePage from "./JDGeneratePage";
import { useNavigate } from 'react-router-dom';
import JDIdealCandidate from "./JDIdealCandidate";
import logo from './../../../assets/icons/logo.svg';
import JDService from "../../../services/jd.service";
import { MdKeyboardArrowLeft } from "react-icons/md";
import JDTechnicalQAsPage from './JDTechnicalQAsPage';
import { TabView, TabPanel } from 'primereact/tabview';
import { extractJDFilePath } from "../../../utils/common";
import CommonService from "../../../services/common.service";
import { useAuth } from "../../../contextProviders/AuthContext";
import { useToast } from "../../../contextProviders/ToastContext";
import JDInitialScreeingQAsPage from './JDInitialScreeningQAsPage';
import { useJDInfo } from "../../../contextProviders/JDInfoContext";
import LoadingComponent from "../../../components/Common/LoadingComponent";
import JDOpsQAsPage from "./JDOpsQasPage";

export default function JDCreatePage(){
    const navigate = useNavigate();
    const {user} = useAuth();
    const { clearJD, getJDInfo } = useJDInfo();
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const jdInfo = getJDInfo();
    const [uploading, setUploading] = useState(false);
    const { showErrorMessage, showSuccessMessage} = useToast();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    useEffect(() => {
        clearJD();
    }, []);

    const goBack = () => navigate(-1)

    const onFinalizedJD = () => {
        setShowConfirmationDialog(true);
    }

    const submitFinalJD = async () => {
        try {
            setUploading(true);
            const requestData = {
                title: jdInfo?.title,
                duration: jdInfo?.duration,
                experience: jdInfo?.experience?.code,
                detectAnomaly: jdInfo?.detectAnomaly,
                primarySkills: jdInfo?.primarySkills,
                secondarySkills: jdInfo?.secondarySkills,
                startWithSummary: jdInfo?.startWithSummary,
                employmentType: jdInfo?.employmentType?.code,
                bpoOpsAnswerType: jdInfo?.bpoOpsAnswerType?.code,
                idealCandidateText: jdInfo?.idealCandidateText,
                requireCodingQuestions: jdInfo?.requireCodingQuestions,
                initialScreeningQAs: jdInfo?.["initialScreeningQAs"] || [],
                difficultyLevel: jdInfo?.difficultyLevel?.code?.toLowerCase(),
                technicalScreeningQAs: jdInfo?.["technicalScreeningQAs"] || [],
                location: jdInfo?.location?.includes(",") ? 
                    jdInfo?.location?.split(",") : [jdInfo?.location],
            };

            if(jdInfo?.idealCandidateText)
                requestData['idealCandidateText'] = jdInfo?.idealCandidateText;
            if(jdInfo?.idealCandidateAudioUrl)
                requestData['idealCandidateAudioUrl'] = jdInfo?.idealCandidateAudioUrl;

            if(jdInfo?.selectedOption === "UPLOAD" && jdInfo?.uploadedJDFile){
                const fileName = jdInfo?.uploadedJDFile.name.replace(/&/g, '-').replace(/ /g, '-');

                const presignUrl = await CommonService.generatePresignUrlForJD(fileName);
                const {url} = presignUrl.data.data;
                
                await axios.put(url, jdInfo?.uploadedJDFile, { headers: { 'x-ms-blob-type': 'BlockBlob'} });
                requestData['originalJdUrl'] = extractJDFilePath(url, '-');
            }

            const fileName = new Date().getTime() + '.pdf';
            const presignUrl = await CommonService.generatePresignUrlForJD(fileName);
            const {url} = presignUrl.data.data;

            const response = await CommonService.generatePdfFromHtml(jdInfo?.jdHtml);
            const pdfBuffer = response.data.data;
            const pdfArray = new Uint8Array(pdfBuffer.data);
            await axios.put(url, pdfArray, {
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': 'application/pdf',
                    'Content-Length': pdfArray.length
                }
            });

            requestData['jdUrl'] = extractJDFilePath(url, '-');

            const jdResponse = await JDService.createJD(requestData);
            if(jdResponse.status === 201){
                clearJD();
                showSuccessMessage({summary: 'Created', detail: "JD is successfully uploaded"})
                navigate('/dashboard/job-description')
            } 
        }
        catch (error) {
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message})
        }
        finally{
            setUploading(false);
        }
    }

    return (
        <div className="rounded flex flex-col overflow-y-hidden h-screen p-5 pb-0">
            <div className={`${showConfirmationDialog? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-5 rounded-md flex justify-center items-center">
                    {uploading && <LoadingComponent/>}
                    <div className={`${uploading? 'hidden': 'visible'} flex flex-col justify-center items-center gap-5`}>
                        <div className="h-16 w-16 bg-[#F6F7F9] rounded-full justify-center items-center flex"><img src={logo} className="h-11 w-11" alt="logo" /></div>
                        <h2 className="text-[#333333] font-bold text-xl">Are you sure?</h2>
                        <p className="text-brownGray text-base">Would you like to finalize the Job Description?</p>
                        <div className="flex items-center justify-center gap-10 py-5 text-sm">
                            <button className="h-10 border border-primary w-32 font-medium" onClick={() => setShowConfirmationDialog(false)}>NO</button>
                            <button className="bg-primary h-10 rounded w-32 text-white font-medium" onClick={submitFinalJD}>YES</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${!showConfirmationDialog && uploading? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-10 rounded-md w-1/4 flex justify-center items-center">{uploading && <LoadingComponent/>}</div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div
                    onClick={goBack} 
                    className="flex items-center gap-1 text-[#161616] cursor-pointer">
                    <MdKeyboardArrowLeft size={26} />
                    <h1 className='text-xl text-primary font-bold'>Generate Job Description</h1>
                </div>
            </div>
            <div className="overflow-scroll pb-10 px-5">
                <TabView
                    className='text-sm'
                    activeIndex={activeTabIndex}
                    pt={{ panelContainer: {className: 'mt-6'}}}
                    onTabChange={({index}) => setActiveTabIndex(index)}>
                    <TabPanel 
                        header="Job Description"
                        disabled={activeTabIndex !== 0}
                        headerClassName='text-[#999999] font-normal'>
                        <JDGeneratePage setActiveTabIndex={setActiveTabIndex} />
                    </TabPanel>
                    <TabPanel 
                        header="HR Initial screening"
                        disabled={activeTabIndex !== 1}
                        headerClassName='text-[#999999] font-normal'>
                        <JDInitialScreeingQAsPage setActiveTabIndex={setActiveTabIndex} />
                    </TabPanel>
                    { !user?.email?.includes('alorica') && <TabPanel 
                        header='Technical/Ops screening'
                        disabled={activeTabIndex !== 2}
                        headerClassName='text-[#999999] font-normal'>
                        <JDTechnicalQAsPage 
                            onFinalizedJD={onFinalizedJD}
                            setActiveTabIndex={setActiveTabIndex} 
                        />
                    </TabPanel>
                    }
                    { user?.email?.includes('alorica') && <TabPanel 
                        header='Technical/Ops screening'
                        disabled={activeTabIndex !== 2}
                        headerClassName='text-[#999999] font-normal'>
                        <JDOpsQAsPage 
                            onFinalizedJD={onFinalizedJD}
                            setActiveTabIndex={setActiveTabIndex} 
                        />
                    </TabPanel>}
                    <TabPanel 
                        header="Ideal Candidate"
                        disabled={activeTabIndex !== 3}
                        headerClassName='text-[#999999] font-normal'>
                        <JDIdealCandidate
                            onFinalizedJD={onFinalizedJD} 
                            setActiveTabIndex={setActiveTabIndex} 
                        />
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )
}