import ConsoleHelper from "./consoleHelper";

export function getEditorHeaders(){
    return (
        <div className="toolbar-container">
            <span class="ql-formats">
                <select class="ql-font"></select>
                <select class="ql-size"></select>
            </span>
            <span class="ql-formats">
                <button class="ql-list" value="ordered" />
            </span>
        </div>
    );
}

export function uploadFile(presignUrl, file){
    return fetch(presignUrl, {
        method: "PUT",
        body: file,
        headers: {
            'x-ms-blob-type': 'BlockBlob'
        }
    });
}

export function extractResumeFilePath(url, replaceChar) {
    const match = url.match(/\/(resumes\/[^\/?#]+)[^\/*]*$/);
    if (match) {
        const filePath = match[1].replace(/ /g, replaceChar);
        return filePath;
    } else {
        return null;
    }
}

export function extractBlogFilePath(url, replaceChar) {
    const match = url.match(/\/(blogs\/[^\/?#]+)[^\/*]*$/);
    if (match) {
        const filePath = match[1].replace(/ /g, replaceChar);
        return filePath;
    } else {
        return null;
    }
}

export function extractIdealCandidateAudiosFilePath(url, replaceChar) {
    const match = url.match(/\/(ideal-candidate-audios\/[^\/?#]+)[^\/*]*$/);
    if (match) {
        const filePath = match[1].replace(/ /g, replaceChar);
        return filePath;
    } else {
        return null;
    }
}

export function extractJDFilePath(url, replaceChar) {
    const match = url.match(/\/(jds\/[^\/?#]+)[^\/*]*$/);
    if (match) {
        const filePath = match[1].replace(/ /g, replaceChar);
        return filePath;
    } else {
        return null;
    }
}

export const getPaginationLimit = () => {
    const height = window.screen.height;
    if(height < 700) return 5;
    else if(height < 800) return 8
    else if(height < 1000) return 9
    else if(height < 1300) return 12
    else if (height >= 1300) return 15
}

export const getPaginationLimitForJD = () => {
    const height = window.screen.height;
    if(height < 700) return 5;
    else if(height < 800) return 8
    else if(height < 1000) return 9
    else if(height < 1300) return 11
    else if (height >= 1300) return 15
}

export function capitalizeFirstLetterOfEachWord(str) {
    return str?.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
}

export function isChromeBrowser(){
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
}

export function isSafariBrowser() {
    return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
}

export function isFirefoxBrowser() {
    return /Firefox/.test(navigator.userAgent);
}

export const RESOLUTIONS = [
    { label: '480p (SD)', value: 480, width: 854, height: 480 },    // Standard definition (SD)
    { label: '720p (HD)', value: 720, width: 1280, height: 720 },   // High definition (HD)
    { label: '1080p (Full HD)', value: 1080, width: 1920, height: 1080 },  // Full high definition (FHD)
];
export const FRAME_RATES = [
    {  label: '24 fps (Cinematic)', value: 24 },
    {  label: '30 fps (Standard Video)', value: 30 },
    {  label: '60 fps (High Frame Rate)', value: 60 },
]

export function getStreamFromDevice(deviceId){
    try {
        const constraints = {
            audio: { deviceId: { exact: deviceId }}
        };
        return navigator.mediaDevices.getUserMedia(constraints);
    } catch (error) {
        ConsoleHelper.error(`Error accessing media devices: ${JSON.stringify(error)}`);
    }
  };

export function getBrowserName() {
    if (isFirefoxBrowser()) return 'Firefox';
    if (isChromeBrowser()) return 'Chrome';
    if (isSafariBrowser()) return 'Safari';
    return 'Unknown';
}
  