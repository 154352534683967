// export const SCREENING_TYPE = {
//     INITIAL_SCREENING: 'INITIAL_SCREENING',
//     TECHNICAL_SCREENING: 'TECHNICAL_SCREENING'
// }

// export const STORAGE_CONTAINERS = {
//     jds: 'jds',
//     profiles: 'profiles',
//     resumes: 'resumes',
// }

export const RESUME_STAGES = {
    COMPLETED: 'COMPLETED',
    ARCHIVED: 'ARCHIVED',
    IN_QUEUE: 'IN_QUEUE',
    SHORTLISTING: 'SHORTLISTING',
    EXIT_INTERVIEW: 'EXIT_INTERVIEW',
    OPS_SCREENING: 'OPS_SCREENING',
    RESUME_RECEIVED: 'RESUME_RECEIVED',
    RESUME_SCREENING: 'RESUME_SCREENING',
    INITIAL_SCREENING: 'INITIAL_SCREENING',
    TECHNICAL_SCREENING: 'TECHNICAL_SCREENING',
}

export const BPO_OPS_ANSWER_TYPE = {
    VOICE: 'voice',
    TEXT: 'text'
}


export const RESUME_STATUS = {
    NA: 'NA',
    FAILED: 'FAILED',
    EXITED: 'EXITED',
    PENDING: 'PENDING',
    SCHEDULED: 'SCHEDULED',
    COMPLETED: 'COMPLETED',
    IN_PROGRESS: 'IN_PROGRESS',
    RECOMMENDED: 'RECOMMENDED',
    INVITE_SENT: 'INVITE_SENT',
    INVITE_EXPIRED: 'INVITE_EXPIRED',
    NOT_RECOMMENDED: 'NOT_RECOMMENDED',
    ALREADY_COMPLETED: 'ALREADY_COMPLETED',
    SHORTLISTING_IN_PROGRESS: 'SHORTLISTING_IN_PROGRESS',
}


export const SCREENINGS = ['INITIAL_SCREENING', 'TECHNICAL_SCREENING'];