import { useState } from 'react';
import { Rating } from '@smastrom/react-rating';
import { useReportContext } from '../../contextProviders/ReportContext';
import CircularProgress from '../../components/Report/CircularProgress';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';

export default function ReportResumeScreening(){
    const {report} = useReportContext();
    const [showAll, setShowAll] = useState(false);
    const resumeScreening = report?.resume_screening[0] || {};

    const getRating = (rating) => {
        if(rating < 20 || !rating) return 1;
        else if(rating < 39) return 2;
        else if(rating < 59) return 3;
        else if(rating < 74) return 4;
        else return 5;
    }

    if(!resumeScreening || resumeScreening === 0 || !resumeScreening?.score){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>Report is being prepared, will be available shortly.</h1>
            </div>
        )
    }

    return (
        <div className='flex w-full flex-col pt-5 gap-10'>
            <div className='flex justify-center items-center gap-10'>
                {/* <Knob 
                    size={100}
                    value={resumeScreening?.score || 0} 
                    valueTemplate={'{value}%'} 
                    valueColor={resumeScreening?.score < 50 ? '#D30B53' : 
                        (resumeScreening?.score >= 50 && resumeScreening?.score < 80) ? '#B99000' : '#278500'
                    }
                /> */}
                <CircularProgress score={resumeScreening?.score}/>
                <div className='flex gap-2 flex-col'>
                    <label className='text-xl font-bold'>Summary</label>
                    <CustomReactMarkdown data={resumeScreening?.summary}/>
                </div>
            </div>
            <div className={`flex gap-2 flex-col ${resumeScreening?.matchedReason ? 'visible' : 'hidden'}`}>
                <label className='text-xl font-bold'>Matched Reason</label>
                <p className='text-primary'>{resumeScreening?.matchedReason}</p>
            </div>
            <div className={`flex gap-2 flex-col ${resumeScreening?.irrelevant ? 'visible' : 'hidden'}`}>
                <label className='text-xl font-bold'>Irrelevance</label>
                <p className='text-primary'>{resumeScreening?.irrelevant}</p>
            </div>
            <div 
                onClick={() => setShowAll(true)}
                className={`flex items-center gap-1 m-auto cursor-pointer ${!showAll ? 'visible': 'hidden'}`}>
                <span className='font-semibold'>Show more</span>
                <MdOutlineKeyboardArrowDown size={20}/>
            </div>
            <div className={`${showAll ? 'visible': 'hidden'} flex flex-col gap-10`}>
                <div className={`flex gap-2 flex-col ${resumeScreening?.educationRelevanceSummary && resumeScreening?.candidateExperience <= 2 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Education relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_education)} 
                            className={`${resumeScreening?.scoreInfo?.relevant_education ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.educationRelevanceSummary}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.skillRelevanceSummary ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Skill relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_skill)} 
                            className={`${resumeScreening?.scoreInfo?.relevant_skill ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.skillRelevanceSummary}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.experienceRelevanceSummary && resumeScreening?.candidateExperience > 2 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Experience Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_experience)} 
                            className={`${resumeScreening?.scoreInfo?.relevant_experience ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.experienceRelevanceSummary}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.workRelevanceSummary ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Work Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_work)} 
                            className={`${resumeScreening?.scoreInfo?.relevant_work ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.workRelevanceSummary}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.industryRelevance && resumeScreening?.candidateExperience > 2 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Industry Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.industry_relevance)} 
                            className={`${resumeScreening?.scoreInfo?.industry_relevance ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.industryRelevance}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.companyRelevance && resumeScreening?.candidateExperience > 2 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Company Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.company_relevance)} 
                            className={`${resumeScreening?.scoreInfo?.company_relevance ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.companyRelevance}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.missingSkillsSummary ? 'visible' : 'hidden'}`}>
                    <h1 className='text-xl font-bold'>Missing Skills</h1>
                    <p className='text-primary'>{resumeScreening?.missingSkillsSummary}</p>
                </div>
                <div 
                    onClick={() => setShowAll(false)}
                    className={`flex items-center gap-1 m-auto cursor-pointer ${showAll ? 'visible': 'hidden'}`}>
                    <span className='font-semibold'>Show less</span>
                    <MdOutlineKeyboardArrowUp size={20}/>
                </div>
            </div>
        </div>
    )
}