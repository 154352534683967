import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import ConfirmDialog from '../ConfirmDialog';
import { useLocation } from 'react-router-dom';
import SuccessPopup from '../Common/SuccessPopup';
import React, { useState, useCallback } from 'react';
import { MdClear, MdCloudUpload } from 'react-icons/md';
import LoadingComponent from '../Common/LoadingComponent';
import CommonService from '../../services/common.service';
import ResumeService from '../../services/resume.service';
import { useToast } from '../../contextProviders/ToastContext';
import { extractResumeFilePath, uploadFile } from '../../utils/common';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export default function ResumesUploadPopup({ hide, getResumes }) {
  const {showErrorMessage} = useToast();

  const {pathname} = useLocation();
  const jdId = pathname.replace('/dashboard/job-description/', '');

  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const uploadResumes = async () => {
    try {
      const presignUrls = [];
        const uploadFiles = files?.map(async (file) => {
            const response = await CommonService.generatePresignUrlForResume(file.name.replace(/ /g, '-'));
            const url = response.data.data.url;
            presignUrls.push(url);
            return uploadFile(url, file)
        })
        await Promise.all(uploadFiles);
        const data = {
          jdId, 
          urls: presignUrls?.map((item) => extractResumeFilePath(item, '-'))
        }
        const response = await ResumeService.uploadResumes(data);
        if(response.status === 201){
            getResumes();
            // showSuccessMessage({summary: 'Uploaded', detail: 'Resumes uploaded'})
            setFiles([])
        } 
    }
    catch (error) {
        showErrorMessage({
            summary: 'Failed', 
            detail: error?.response?.data?.message
        })
    }
  }

  const handleSumit = async () => {
    setShowConfirmDialog(false);
    setIsUploading(true);
    setError(null);
    
    try {
      await uploadResumes();
      setFiles([]);
      setError(null);
      setIsUploaded(true);

      await new Promise(resolve => setTimeout(() => {
        hide();
        setIsUploaded(false);
        setIsUploading(false);
        // showSuccessMessage({
        //     summary: 'Uploaded resume', 
        //     detail: 'Successfully uploaded resumes'
        // });
      }, 2000));
    } catch (err) {
      setError('Upload failed. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setError(null);
    
    // Filter out valid and oversized files
    const validFiles = acceptedFiles.filter(file => file.size <= MAX_FILE_SIZE);
    const oversizedFiles = acceptedFiles.filter(file => file.size > MAX_FILE_SIZE);
    
    if (rejectedFiles.length > 0 || oversizedFiles.length > 0) {
        setError('Some files were rejected. Please only upload PDF files under 5MB.');
    }

    // Append new valid files without removing existing ones
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  }, [setFiles]);


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {'application/pdf': ['.pdf']},
    multiple: true,
    preventDropOnDocument: true
  });

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center p-4 bg-black bg-opacity-50">
        {showConfirmDialog && (
            <ConfirmDialog
              message={`You have uploaded ${files.length} PDF files. Would you like to save them?`}
              accept={handleSumit} 
              cancel={() => setShowConfirmDialog(false)}    
            />
        )}
        {isUploaded && (
            <SuccessPopup   
                message="All resumes have been uploaded successfully."
            />
        )}
        
        <div className="w-full max-w-md bg-white rounded-lg shadow overflow-hidden">
            {isUploading && <LoadingComponent />}
            <div className={`${isUploading? 'hidden': 'visible'} p-6 flex flex-col gap-5`}>
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-base font-semibold text-darkGray">Upload job description</h1>
                    <MdClear
                        size={20}
                        onClick={() => {
                            setFiles([]);
                            setError(null);
                            hide();
                        }}
                        className="text-brownGray cursor-pointer"
                    />
                </div>

                <div 
                    {...getRootProps()} 
                    className={`border-[1px] border-dashed rounded-lg p-6 mb-4 cursor-pointer border-blue`}
                >
                    <input {...getInputProps()} />
                    <div className="text-center font-normal">
                      <MdCloudUpload className="mx-auto text-4xl text-primary mb-2" />
                      <p className="text-darkGray text-base">
                          {isDragActive
                          ? "Drop the files here ..."
                          : "Drag & drop files here, or click to select files"}
                      </p>
                      {/* <p className="text-sm text-darkGray mt-1">Supports PDF files up to 5MB</p> */}
                    </div>
                </div>

                {error && (
                    <div className="mb-4 p-3 bg-lr text-dr rounded">
                      {error}
                    </div>
                )}

                {files.length > 0 && (
                    <div className="mb-4 max-h-40 overflow-y-auto text-darkGray">
                    <h2 className="text-base font-semibold mb-2">Selected files:</h2>
                    <ul className="space-y-3 my-4">
                        {files.map((file, index) => (
                        <li 
                          key={`${file?.name}-${index?.toString()}`} 
                          className="text-sm text-gray-600 flex justify-between items-center">
                            <span className="truncate flex-grow">{file.name}</span>
                            <span className="text-gray-500 ml-2">{formatFileSize(file.size)}</span>
                        </li>
                        ))}
                    </ul>
                    </div>
                )}

                <div className="flex justify-end space-x-4">
                    <button
                        onClick={() => {
                            setFiles([]);
                            setError(null);
                            hide();
                        }}
                        className="px-4 py-2 text-blue text-sm border-blue border-[1px] rounded-md"
                        disabled={isUploading}>
                        Cancel
                    </button>
                    <button
                        onClick={() => setShowConfirmDialog(true)}
                        disabled={files.length === 0 || isUploading}
                        className='px-4 py-2 bg-blue text-sm rounded-md text-white'>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
  );
}

ResumesUploadPopup.propTypes = {
  resumes: PropTypes.array,
  setResumes: PropTypes.func,
  hide: PropTypes.func.isRequired
};