import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { CgTranscript } from "react-icons/cg";
import TranscriptComponent from '../../components/Report/TranscriptComponent';
import { useReportContext } from '../../contextProviders/ReportContext';
import moment from 'moment';

export default function ReportInitialScreening(){
    const {report} = useReportContext();
    const [isPlaying, setIsPlaying] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false);
    const initialScreening = report?.initial_screening[0] || {};

    const gmtDateTime = moment.utc(initialScreening?.start_time, "YYYY-MM-DD HH")
    const startTime = gmtDateTime.local().format('YYYY-MMM-DD h:mm A');

    const videoRef = useRef(null);

    const excludeKeys = ['id', 'video_path', 'total_score', 'total_feedback', 'summary', 'relevance', 'roles_and_responsibilities', 'start_time'];

    const formatLabel = (key) => {
        return key
          .split('_')
          .map((word, index) => 
            index === 0 
              ? word.charAt(0).toUpperCase() + word.slice(1) 
              : word
          )
          .join(' ');
    };
    
    const formatValue = (value) => {
        if (typeof value === 'boolean')
          return value ? 'Yes' : 'No';
        return value;
    };

    if(!report?.initial_screening || report?.initial_screening?.length === 0){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>Report is being prepared, will be available shortly.</h1>
            </div>
        )
    }

    const handlePlay = () => {
        setIsPlaying(true);
    };
    
    const handlePause = () => {
        setIsPlaying(false);
    };

    return (
        <div className='flex flex-col gap-5'>
            <div className='flex justify-between items-center'>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-row justify-between flex-1 gap-5'>
                        <ReactPlayer
                            ref={videoRef}
                            controls
                            pip={false}
                            playing={isPlaying}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            className="react-player"
                            url={initialScreening?.video_path}
                        />
                        <div className='flex flex-col gap-4 flex-1'>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.start_time ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Date of the interview :</h3>
                                <span className=''>{initialScreening?.start_time ? moment(startTime).format('DD MMM YYYY h:mm a') : 'Not captured' }</span>
                            </div>
                            {Object.entries(initialScreening)
                                .filter(([key]) => !excludeKeys.includes(key))
                                .map(([key, value]) => (
                                    <div key={key} className={'flex items-center gap-2 text-primary'}>
                                        <h3 className='font-semibold'>{formatLabel(key)}:</h3>
                                        <h4 className=''>{formatValue(value)}</h4>
                                    </div>
                                ))
                            }
                            {/* <div className={`flex items-center gap-2 text-primary ${initialScreening?.current_ctc ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Current CTC:</h3>
                                <h4 className=''>{initialScreening?.current_ctc}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.expected_ctc ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Expected CTC:</h3>
                                <h4 className=''>{initialScreening?.expected_ctc}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.notice_period ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Notice Period:</h3>
                                <h4 className=''>{initialScreening?.notice_period}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.location ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Location:</h3>
                                <h4 className=''>{initialScreening?.location}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.is_comfortable_with_night_shifts ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Comfortable with night Shifts:</h3>
                                <h4 className=''>{initialScreening?.is_comfortable_with_night_shifts}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.is_interested ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Interested</h3>
                                <h4 className=''>{initialScreening?.is_interested ? 'YES' : 'NO '}</h4>
                            </div> */}
                            <div className='flex items-center gap-2'>
                                <p className='font-semibold text-primary'>Transcript of the interview</p>
                                <CgTranscript 
                                    size={28}
                                    className="cursor-pointer"
                                    onClick={() => setShowTranscript(!showTranscript)}
                                />
                            </div>
                        </div>
                    </div>
                    {showTranscript && <TranscriptComponent id={initialScreening?.id} hide={() => setShowTranscript(false)}/>}
                </div>
            </div>
            <div className={`${initialScreening?.roles_and_responsibilities?.length > 0 ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Roles And Responsibilities</h4>
                <ul className='flex gap-5 flex-wrap my-5'>
                    {initialScreening?.roles_and_responsibilities?.map((item, index) => (
                        <li key={index?.toString()} 
                            className='bg-lightBlue p-3 rounded-lg text-primary'>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={`${initialScreening?.summary ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Summary</h4>
                <p className='text-primary my-5'>{initialScreening?.summary}</p>
            </div>
            <div className={`${initialScreening?.relevance ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Relevance</h4>
                <p className='text-primary my-5'>{initialScreening?.relevance}</p>
            </div>
        </div>
    )
}