import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  timeout: 1 * 60 * 1000,
});

axiosInstance.interceptors.request.use(
    config => {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'))
      if(!config.url.includes('/auth') || config.url.includes('/config')){
        if (accessToken) 
          config.headers.Authorization = `Bearer ${accessToken.token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      if (error.response && error.response.status === 401 && !error.response?.config?.url.includes("/auth/")) {
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        return window.location.href = '/auth/login'
      }
      return Promise.reject(error);
    }
);

export default axiosInstance;