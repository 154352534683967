import moment from "moment";
import ReactQuill from "react-quill";
import { useEffect, useState } from "react"
import BlogService from "../../services/blog.service";
import { useNavigate, useParams } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import LoadingComponent from "../Common/LoadingComponent";
import { Helmet } from "react-helmet-async";

export default function BlogPage(){

    const { id } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    useEffect(() => {
        async function init(){
            try {
                setLoading(true);
                const response = await BlogService.getBlog(id);
                setLoading(false);
                if(response.status === 200)
                    setBlog(response?.data?.data)
            } catch (error) {
                setLoading(false);
            }
        }
        init();

    }, []);

    if(loading){
        return (
            <div className='w-full md:max-w-[75%] mx-auto py-10 flex flex-col md:flex-row justify-center md:px-0 px-5 gap-5'>
                <div className="flex-1 flex flex-col gap-5 justify-center items-center">
                    <LoadingComponent/>
                </div>
            </div>
        )
    }

    return (
        <div className='w-full md:max-w-[75%] mx-auto py-5 flex-col flex justify-center gap-5'>
            {blog && (
                <Helmet>
                    <title>{blog?.description} </title>
                    <meta name="description" content={blog?.description} />
                </Helmet>
            )}
            <div 
                onClick={goBack}
                className="flex items-center gap-1 text-primary cursor-pointer">
                <MdKeyboardArrowLeft size={26} />
                <h1 className='text-xl text-primary font-bold'>Blogs</h1>
            </div>
            <div className="flex-1 flex flex-col justify-center pt-5">
                <div className="flex flex-col gap-1">
                    <h2 className="text-brownGray text-sm font-normal">{blog?.title}</h2>
                    <h3 className="text-4xl font-bold">{blog?.description}</h3>
                    <span className="text-xs text-brownGray font-normal">{moment(blog?.createdAt).fromNow()}</span>
                    <img
                        className="my-10 w-3/4 m-auto"
                        src={blog?.imageUrl}
                        alt="Blog"
                    />
                </div>
                <div className="py-5">
                    <ReactQuill
                        readOnly={true}
                        theme={"bubble"}
                        value={blog?.content.replace(/&lt;/g, '<').replace(/&gt;/g, '>')}
                    />
                </div>
            </div>
        </div>
    )
}